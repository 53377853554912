import React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import * as System from '../../design-system'

const Wrap = styled.div`
  padding: 300px 0;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrap>
      <System.Text text="NOT FOUND" />
      <br />
      <br />
      <System.Text
        text="You just hit a route that doesn&#39;t exist... the sadness."
        type="Body"
      />
    </Wrap>
  </Layout>
)

export default NotFoundPage
